import React, { useState, useEffect, useRef } from 'react';
import '../styles/HammerChallenge.css';
import bellSound from '../assets/bell.wav'; // Ensure the path to the sound file is correct

const HammerChallenge = () => {
    const [power, setPower] = useState(0);
    const [isHolding, setIsHolding] = useState(false);
    const [fillHeight, setFillHeight] = useState(0);
    const [hitHeight, setHitHeight] = useState(0);
    const intervalRef = useRef(null);
    const [hit, setHit] = useState(false);
    const [message, setMessage] = useState('Tap and hold to build power!');
    const bellAudioRef = useRef(new Audio(bellSound));

    const maxPower = 85;
    const maxHeight = 85; // in vh
    const optimalPowerRange = [80, 84]; // Define the optimal power range to hit the bell
    const maxRotation = 60; // Maximum rotation in degrees

    useEffect(() => {
        console.log(window.THREE); // Should log the THREE.js object
console.log(window.VANTA); // Should log the VANTA object with FOG available

        const handleMouseDown = () => {
            setIsHolding(true);
            setMessage(''); // Clear message state on hold
        };

        const handleMouseUp = () => {
            setIsHolding(false);
            let height = (power / maxPower) * maxHeight;
            if(power === 85){
                height = 40;
                setMessage('whiff');
            }
            setFillHeight(height);
            if (power >= optimalPowerRange[0] && power <= optimalPowerRange[1]) {
                setTimeout(() => {
                    setHit(true);
                    setMessage('NICE!');
                    bellAudioRef.current.play();
                    setTimeout(() => {
                        bellAudioRef.current.pause();
                    }, 1500);
                }, 500);
            }
            setPower(0);
            setTimeout(() => {
                setHitHeight(40); // Move the hit object down by 40px
            }, 70);
            setTimeout(() => {
                setHitHeight(0); // Reset hit object position
                setFillHeight(0); // Reset fill height after a delay
            }, 600); 
            setTimeout(() => {
                setHit(false); // Reset hit state after a delay
            }, 1500);
        };

        document.addEventListener('touchstart', handleMouseDown);
        document.addEventListener('touchend', handleMouseUp);
        document.addEventListener('mousedown', handleMouseDown);
        document.addEventListener('mouseup', handleMouseUp);
        
        // Handle touchstart as mousedown

        return () => {
            document.removeEventListener('mousedown', handleMouseDown);
            document.removeEventListener('mouseup', handleMouseUp);

            document.removeEventListener('touchstart', handleMouseDown);
            document.removeEventListener('touchend', handleMouseUp);
        };
    }, [power]);

    useEffect(() => {
        if (isHolding) {
            intervalRef.current = setInterval(() => {
                setPower(prev => {
                    if (prev >= maxPower) {
                        clearInterval(intervalRef.current);
                        return maxPower;
                    }
                    return prev + 5; // Increase power faster
                });
            }, 50);
        } else {
            clearInterval(intervalRef.current);
        }
        return () => clearInterval(intervalRef.current);
    }, [isHolding]);

    return (
        <div className="challenge-container">
            <div className="bulk-frog" style={{ transform: `rotate(-${(power / maxPower) * maxRotation}deg)` }}>
                <div className={`message ${message === 'NICE!' || message === 'Tap and hold to build power!' ? 'show' : ''}`}>{message}</div>
                <div className={`whiff ${message === 'whiff' ? 'show' : ''}`}>{message}</div>
            </div>
            <div className="hammer" style={{ transform: `translateY(${hitHeight}px)` }}></div>
            <div className="game-area">
                <div className="gauge">
                    <div className="weight" style={{ height: `${fillHeight}vh`, transition: isHolding ? 'none' : 'height 0.8s ease' }}></div>
                    <div className={`circle ${hit ? 'hit' : ''}`}></div>
                </div>
            </div>
        </div>
    );
};

export default HammerChallenge;
