import React from 'react';
import '../styles/Dashboard.css';
import bf from '../assets/bulk_frog.png'; // Ensure this image file is created and imported


const Dashboard = () => {
    return (
        <div className='dashboard-container'>
            <h1 className='tiny5-regular'>BULK FROG</h1>
            <div className='img-button'>
                <img src={bf} alt="bulkfrog" />
                <div className='btn-container'>
                    <a href="https://x.com/FrogBulk" target="_blank" rel="noopener noreferrer">
                        <button className='button-33'>X</button>
                    </a>
                    <a href="https://t.me/bulkfrogportal" target="_blank" rel="noopener noreferrer">
                    <button className='button-33'>Telegram</button>
                    </a>
                    <a href="https://pump.fun/HCZFDjK5wRp7EYmscGhHZWBfws7Sb4fWiSpqBHaupump" target="_blank" rel="noopener noreferrer">
                    <button className='button-33'>Pump Fun</button>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;