// src/App.js
import React from 'react';
import './App.css';
import HammerChallenge from './components/HammerChallenge';
import Dashboard from './components/Dashboard';

function App() {
  return (
    <div className="App 'safari_only'">
      <HammerChallenge /> 
      <Dashboard />
    </div>
  );
}

export default App;
